<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <router-view />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
html,
body {
  margin: 0px;
  padding: 0px;
}
</style>
