<template>
  <div class="legitchecks">
    <div class="item" v-for="(item, index) in items" :key="index">
      <img class="image" :src="item.imageList[0].preview" />
      <img
        class="brand"
        v-if="item.tag && item.tag.icon"
        :src="item.tag.icon"
      />
      <img
        v-if="item.appraise.result === 1"
        class="checkresult"
        src="https://file2.snkrsgram.com/images/2022/05/22/3a6470e84a214139442b778262538f12.png"
      />
      <img
        v-else-if="item.appraise.result === 2"
        class="checkresult"
        src="https://file2.snkrsgram.com/images/2022/05/22/beb479a861ecc7a32029d9a241fd0183.png"
      />
      <div class="info">
        <div class="title">{{ item.title }}</div>
        <div class="checktime">
          Check time {{ item.appraise.checkElapsedMinutes }} minutes
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.legitchecks {
  display: flex;
  padding: 10px;
  margin: 0 -10px;

  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }

  .item {
    width: 210px;
    margin-right: 50px;
    background: #ffffff;
    box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.1);
    border-radius: 1px;
    position: relative;

    .brand {
      position: absolute;
      bottom: 5px;
      right: 5px;
      width: 35px;
      height: 35px;
      object-fit: cover;
      // border: 1px dashed #0a0a0a;
    }

    .checkresult {
      position: absolute;
      top: 0;
      left: 0;
      width: 42px;
    }

    .image {
      width: 210px;
      height: 210px;
      object-fit: cover;
    }

    .info {
      padding: 11px;

      .title {
        font-size: 13px;
        font-family: Roboto-Medium, Roboto;
        font-weight: 500;
        color: #0a0a0a;
        line-height: 15px;
        text-shadow: 0px 4px 13px rgba(0, 0, 0, 0.1);

        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        text-align: justify;
        word-break: break-all;
        text-overflow: ellipsis;
      }
      .checktime {
        margin-top: 11px;
        font-size: 9px;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: #8f8f96;
        line-height: 10px;
        text-shadow: 0px 4px 13px rgba(0, 0, 0, 0.1);
      }
    }
  }
}
</style>
