<template>
  <div class="home">
    <div class="header">
      <img
        class="app-img1"
        src="https://file2.snkrsgram.com/images/2022/05/21/d0dca406b06b44281d349604ef93f86f.png"
      />
      <img
        class="app-img2"
        src="https://file2.snkrsgram.com/images/2022/05/21/df4934e7bfefefb152d7b8d4b27cd703.png"
      />
      <div class="nav">
        <div class="left">
          <img
            class="logo"
            src="https://file2.snkrsgram.com/images/2022/07/20/d30a7754cdc3aae0c02d4a85ecdbc3d7.png"
          />
          <span class="title">GetNice</span>
        </div>
        <div class="right">
          <a>ABOUT</a>
        </div>
      </div>
      <div class="content">
        <div class="left">
          <div class="slogn">Born For Fashion</div>
          <div class="slogn2">
            <p>Philippines Local Fashion Market</p>
            <p>Sneakers, Apparel, Electronics, Toys...</p>
            <p>Professioal Speedy Legit-Check</p>
            <p>Diverse Community And Latest News</p>
          </div>
          <div class="learn-more">Learn more about the App</div>
          <div class="appstores">
            <div class="item">
              <a
                href="https://apps.apple.com/us/app/snkrsgram/id1601817475"
                target="_blank"
              >
                <img
                  class="btn"
                  src="https://file2.snkrsgram.com/images/2022/05/21/4d5b5b7b581fe3d4eeb22d456a280ade.png"
                />
              </a>
              <div class="qrcode">
                <img
                  src="https://file2.snkrsgram.com/images/2022/05/21/867ddd1f1d5995821c6f8b4c9ebe3d6f.png"
                />
              </div>
            </div>
            <div class="item">
              <a
                href="https://play.google.com/store/apps/details?id=com.snkrs.gram.android&gl=PH"
                target="_blank"
              >
                <img
                  class="btn"
                  src="https://file2.snkrsgram.com/images/2022/05/21/413c6daab1da55dcbfec0381f3dd42ea.png"
                />
              </a>
              <div class="qrcode">
                <img
                  src="https://file2.snkrsgram.com/images/2022/05/21/582ea6c35a96338480be4902a716ba3e.png"
                />
              </div>
            </div>
          </div>
          <div class="scan-text">
            Scan this QR code with your smartphone to download the free app.
          </div>
        </div>
        <div class="right"></div>
      </div>
    </div>
    <div class="main">
      <div
        v-if="mallData && mallData.banners && mallData.banners.length"
        class="banners"
      >
        <swiper :options="swiperOption">
          <swiper-slide
            v-for="(banner, index) in mallData.banners"
            :key="index"
          >
            <a class="banner-item">
              <img :src="banner.image" />
            </a>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
      <!-- 
      第一行改成popular brand 数据就和我们app里目前的的popular brand同步
      第二行这个改成most popular outwear & electronic product 我们针对二级类目apparel和electronic里面进行销量的排序也别手动选，数据也和我们后台同步就行。
    -->
      <div
        class="section"
        v-if="
          mallData &&
          mallData.popularSneakers &&
          mallData.popularSneakers.length
        "
      >
        <div class="section-title">Most Popular</div>
        <div class="section-slogn">
          Most popular outwear & electronic product
        </div>
        <div class="section-content">
          <most-popular :items="mallData.popularSneakers" />
        </div>
      </div>
      <div
        class="section"
        v-if="
          mallData && mallData.popularBrands && mallData.popularBrands.length
        "
      >
        <div class="section-title">Popular brand</div>
        <div class="section-content">
          <popular-brands :items="mallData.popularBrands" />
        </div>
      </div>
      <div class="section" v-if="legitChecks && legitChecks.length">
        <div class="section-title">Sneakers Legit-Check</div>
        <div class="section-slogn">Authenticate Your Favourite Sneakers</div>
        <div class="section-content">
          <legit-checks :items="legitChecks" />
        </div>
      </div>
    </div>
    <div class="footer">
      <hottest-topics />
      <div class="footer-info">
        <div class="infos">
          <div class="info">
            <div class="logo">
              <img
                src="https://file2.snkrsgram.com/test/images/2021/10/27/ae6ca1f7a9ad0ed591df4dfe9937d850.png"
              />
              <span>GetNice</span>
            </div>
            <div class="solgan">Get Nice Here, Get Nice Everyday</div>
          </div>
          <div class="info">
            <div class="title1">ABOUT</div>
            <div class="title2">
              <a href="https://www.snkrsgram.com/html/agreement"
                >GetNice User Agreement</a
              >
            </div>
            <div class="title2">
              <a href="/html/privacy">GetNice Privacy Policy</a>
            </div>
          </div>
          <div class="info">
            <div class="title1">SUPPORT</div>
            <div class="title2">snkrsgram@gmail.com</div>
          </div>
          <div class="info">
            <div class="title1">SOCIAL</div>
            <div class="title2">
              <a href="https://www.facebook.com/snkrsgram">Facebook</a>
            </div>
            <div class="title2">
              <a href="https://www.instagram.com/snkrsgramph/">Instrgram</a>
            </div>
            <div class="title2">
              <a href="https://www.youtube.com/channel/UCDOik811dtQr6f_cgzZqxEw"
                >Youtube</a
              >
            </div>
          </div>
        </div>
        <div class="copyright">@2022 GetNice</div>
      </div>
    </div>
  </div>
</template>

<script>
import HottestTopics from "@/components/HottestTopics";
import MostPopular from "@/components/MostPopular";
import PopularBrands from "@/components/PopularBrands";
import LegitChecks from "@/components/LegitChecks";

export default {
  name: "Home",
  head: {
    title: "Snkrsgram",
  },
  components: { HottestTopics, MostPopular, PopularBrands, LegitChecks },
  data() {
    return {
      swiperOption: {
        slidesPerView: 1,
        // 设置分页器
        pagination: {
          el: ".swiper-pagination",
          // 设置点击可切换
          clickable: true,
        },
        // 设置自动轮播
        autoplay: {
          delay: 5000,
        },
        // 设置轮播可循环
        loop: true,
      },
      mallData: null,
      legitChecks: [],
    };
  },
  mounted() {
    this.loadMall();
    this.loadLegitCheck();
  },
  methods: {
    async loadMall() {
      const ret = await this.axios.get("https://www.snkrsgram.com/front/index");
      if (ret.data && ret.data.success) {
        this.mallData = ret.data.data;
      }
      console.log(this.mallData);
    },
    async loadLegitCheck() {
      const ret = await this.axios.get(
        "https://www.snkrsgram.com/api/appraise/newest"
      );
      if (ret.data && ret.data.success) {
        this.legitChecks = ret.data.data;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  margin: auto;
  width: 1344px;
}
.header {
  background-image: url(https://file2.snkrsgram.com/images/2022/05/21/36ad86465e72ef844b0a5a4cb9611b8c.png);
  background-size: cover;
  height: 685px;
  padding: 28px 176px;
  position: relative;

  .app-img1 {
    position: absolute;
    width: 253px;
    bottom: 109px;
    right: 374px;
    z-index: 100;
  }

  .app-img2 {
    position: absolute;
    width: 264px;
    bottom: 39px;
    right: 176px;
    z-index: 200;
  }

  .nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      display: flex;
      align-items: center;
      .logo {
        width: 26px;
      }
      .title {
        font-size: 22px;
        font-family: Roboto-Bold, Roboto;
        font-weight: bold;
        color: #ff3140;
        margin-left: 8px;
      }
    }
    .right {
      a {
        font-size: 20px;
        font-family: Roboto-Bold, Roboto;
        font-weight: bold;
        color: #ffffff;
      }
    }
  }

  .content {
    text-align: left;
    margin-top: 102px;
    .left {
      .slogn {
        font-size: 30px;
        font-family: Roboto-Bold, Roboto;
        font-weight: bold;
        color: #ffffff;
      }

      .slogn2 {
        margin-top: 10px;
        p {
          font-size: 24px;
          font-family: Roboto-Light, Roboto;
          font-weight: 300;
          color: #ffffff;
          margin: 5px 0;
        }
      }

      .learn-more {
        margin-top: 20px;
        font-size: 10px;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: #909090;
      }

      .appstores {
        margin-top: 10px;
        display: flex;

        .item {
          margin-right: 60px;
          text-align: center;
          .btn {
            width: 119px;
            border: 2px dashed #909090;
          }

          .qrcode {
            margin-top: 13px;
            img {
              width: 120px;
            }
          }
        }
      }

      .scan-text {
        margin-top: 27px;
        font-size: 10px;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: #ffffff;
        line-height: 12px;
      }
    }
  }
}
.main {
  padding: 0 54px;
  margin: 26px auto;
  width: 991px;
  .banners {
    margin: 84px auto;
    .banner-item {
      img {
        width: 100%;
        height: 242px;
        object-fit: cover;
      }
    }
  }

  .section {
    margin: 0 auto;
    margin-top: 42px;
    .section-title {
      font-size: 20px;
      font-family: Roboto-Bold, Roboto;
      font-weight: bold;
      color: #0a0a0a;
      line-height: 24px;
    }
    .section-slogn {
      margin-top: 8px;
      font-size: 12px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: #000000;
      line-height: 13px;
    }
    .section-content {
      margin-top: 34px;
    }
  }
}

.footer {
  background-image: url(https://file2.snkrsgram.com/images/2022/05/21/6db5beb6c3d48962df4a5ef90419ee69.png);
  background-size: cover;
  height: 1148px;
  position: relative;

  .footer-info {
    position: absolute;
    bottom: 0px;
    height: 297px;
    width: 100%;
    background: rgba(10, 10, 10, 0.6);

    .infos {
      padding: 85px 218px 0 218px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      text-align: left;
      color: #fff;
      a {
        color: #fff;
        text-decoration: none;
      }
      .info {
        .logo {
          display: flex;
          align-items: center;
          img {
            width: 30px;
            height: 30px;
            margin-right: 8px;
          }
          span {
            font-size: 27px;
            font-weight: 900;
          }
        }
        .solgan {
          font-size: 17px;
          font-weight: 500;
        }
        .title1 {
          margin-bottom: 10px;
          font-size: 13px;
          font-family: Roboto-Bold, Roboto;
          font-weight: bold;
          color: #ffffff;
          line-height: 15px;
          letter-spacing: 2px;
        }
        .title2 {
          line-height: 20px;
          font-size: 11px;
          font-family: Roboto-Medium, Roboto;
          color: #ffffff;
        }
      }
    }

    .copyright {
      padding: 55px 218px 0 218px;
      font-size: 9px;
      font-family: Roboto-Medium, Roboto;
      font-weight: 500;
      color: #ffffff;
      line-height: 11px;
    }
  }
}
</style>
