<template>
  <div class="home">
    <div class="header" :style="{ height: headerHeight }">
      <div class="header-content">
        <div class="nav">
          <div class="left">
            <img
              class="logo"
              src="https://file2.snkrsgram.com/images/2022/07/20/d30a7754cdc3aae0c02d4a85ecdbc3d7.png"
            />
            <span class="title">GetNice</span>
          </div>
          <div class="right">
            <a>ABOUT</a>
          </div>
        </div>
        <div class="content">
          <div class="left">
            <div class="slogn">Born For Fashion</div>
            <div class="slogn2">
              <p>Philippines Local Fashion Market</p>
              <p>Sneakers, Apparel, Electronics, Toys...</p>
              <p>Professioal Speedy Legit-Check</p>
              <p>Diverse Community And Latest News</p>
            </div>
            <button class="download" @click="download">
              Download iOS/Android
            </button>
          </div>
          <div class="right"></div>
        </div>
      </div>
    </div>
    <div class="main">
      <div
        v-if="mallData && mallData.banners && mallData.banners.length"
        class="banners"
      >
        <swiper :options="swiperOption">
          <swiper-slide
            v-for="(banner, index) in mallData.banners"
            :key="index"
          >
            <a class="banner-item">
              <img :src="banner.image" />
            </a>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
      <div
        class="section"
        v-if="
          mallData &&
          mallData.popularSneakers &&
          mallData.popularSneakers.length
        "
      >
        <div class="section-title">Most Popular</div>
        <div class="section-slogn">
          Most popular outwear & electronic product
        </div>
        <div class="section-content">
          <most-popular :items="mallData.popularSneakers" />
        </div>
      </div>
      <div
        class="section"
        v-if="
          mallData && mallData.popularBrands && mallData.popularBrands.length
        "
      >
        <div class="section-title">Popular brand</div>
        <div class="section-content">
          <popular-brands :items="mallData.popularBrands" />
        </div>
      </div>
      <div class="section" v-if="legitChecks && legitChecks.length">
        <div class="section-title">Sneakers Legit-Check</div>
        <div class="section-slogn">Authenticate Your Favourite Sneakers</div>
        <div class="section-content">
          <legit-checks :items="legitChecks" />
        </div>
      </div>
    </div>
    <div class="footer" :style="{ height: footerHeight }">
      <hottest-topics />
      <div class="footer-info">
        <div class="infos">
          <div class="info">
            <div class="title1">ABOUT</div>
            <div class="title2">
              <a href="https://www.snkrsgram.com/html/agreement"
                >GetNice User Agreement</a
              >
            </div>
            <div class="title2">
              <a href="https://www.snkrsgram.com/html/privacy"
                >GetNice Privacy Policy</a
              >
            </div>
          </div>
          <div class="info">
            <div class="title1">SUPPORT</div>
            <div class="title2">snkrsgram@gmail.com</div>
          </div>
          <div class="info">
            <div class="title1">SOCIAL</div>
            <div class="title2">
              <a href="https://www.facebook.com/snkrsgram">Facebook</a>
            </div>
            <div class="title2">
              <a href="https://www.instagram.com/snkrsgramph/">Instrgram</a>
            </div>
            <div class="title2">
              <a href="https://www.youtube.com/channel/UCDOik811dtQr6f_cgzZqxEw"
                >Youtube</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MostPopular from "@/components/MostPopularH5";
import HottestTopics from "@/components/HottestTopicsH5";
import PopularBrands from "@/components/PopularBrandsH5";
import LegitChecks from "@/components/LegitChecksH5";

export default {
  name: "Home",
  head: {
    title: "Snkrsgram",
  },
  components: { MostPopular, HottestTopics, PopularBrands, LegitChecks },
  data() {
    return {
      screenWidth: 0,
      headerHeight: "0px",
      footerHeight: "0px",
      swiperOption: {
        slidesPerView: 1,
        // 设置分页器
        pagination: {
          el: ".swiper-pagination",
          // 设置点击可切换
          clickable: true,
        },
        // 设置自动轮播
        autoplay: {
          delay: 5000,
        },
        // 设置轮播可循环
        loop: true,
      },
      mallData: null,
      legitChecks: [],
    };
  },
  created() {
    this.resetSize();
    window.addEventListener("resize", this.resetSize);
  },
  mounted() {
    this.loadMall();
    this.loadLegitCheck();
  },
  methods: {
    resetSize() {
      this.screenWidth = document.body.clientWidth;
      const headerImageWidth = 750;
      const headerImageHeight = 638;
      this.headerHeight =
        (this.screenWidth / headerImageWidth) * headerImageHeight + "px";

      const footerImageWidth = 750;
      const footerImageHeight = 1054;
      this.footerHeight =
        (this.screenWidth / footerImageWidth) * footerImageHeight + "px";
      // console.log(this.screenWidth);
      // console.log(this.headerHeight);
      // console.log(this.footerHeight);
    },
    async loadMall() {
      const ret = await this.axios.get("https://www.snkrsgram.com/front/index");
      if (ret.data && ret.data.success) {
        this.mallData = ret.data.data;
      }
    },
    async loadLegitCheck() {
      const ret = await this.axios.get(
        "https://www.snkrsgram.com/api/appraise/newest"
      );
      if (ret.data && ret.data.success) {
        this.legitChecks = ret.data.data;
      }
    },
    download() {
      var androidUrl =
        "https://play.google.com/store/apps/details?id=com.snkrs.gram.android&gl=PH";
      var iphoneUrl = "https://apps.apple.com/us/app/snkrsgram/id1601817475";
      if (this.isAndroid()) {
        window.open(androidUrl, "_blank");
      } else if (this.isIphone()) {
        window.open(iphoneUrl, "_blank");
      } else {
        window.open(androidUrl, "_blank");
      }
    },
    isAndroid() {
      var sUserAgent = navigator.userAgent.toLowerCase();
      var bIsAndroid = sUserAgent.match(/android/i);
      return bIsAndroid && bIsAndroid.index !== -1;
    },

    isIphone() {
      var sUserAgent = navigator.userAgent.toLowerCase();
      var bIsIphoneOs = sUserAgent.match(/iphone/i);
      return bIsIphoneOs && bIsIphoneOs.index !== -1;
    },

    isWechat() {
      var sUserAgent = navigator.userAgent.toLowerCase();
      var bIsWeixin = sUserAgent.match(/micromessenger/i);
      return bIsWeixin && bIsWeixin.index !== -1;
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  background-image: url(https://file2.snkrsgram.com/images/2022/05/22/58fb11432fe3635d875881f9b50d25a3.png);
  background-size: cover;
  .header-content {
    padding: 22px;
    .nav {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        display: flex;
        align-items: center;
        .logo {
          width: 22px;
        }
        .title {
          font-size: 18px;
          font-family: Roboto-Bold, Roboto;
          font-weight: bold;
          color: #ff3140;
          margin-left: 8px;
        }
      }
      .right {
        a {
          font-size: 13px;
          font-family: Roboto-Bold, Roboto;
          font-weight: bold;
          color: #ffffff;
        }
      }
    }

    .content {
      text-align: left;
      margin-top: 30px;
      .left {
        .slogn {
          font-size: 26px;
          font-family: Roboto-Bold, Roboto;
          font-weight: bold;
          color: #ffffff;
        }

        .slogn2 {
          margin-top: 11px;
          p {
            font-size: 13px;
            font-family: Roboto-Light, Roboto;
            font-weight: 300;
            color: #ffffff;
            margin: 5px 0;
          }
        }

        .download {
          margin-top: 30px;
          width: 166px;
          height: 31px;
          background: linear-gradient(138deg, #ff344b 0%, #ff5f7c 100%);
          border-radius: 15px;
          font-size: 13px;
          font-family: Roboto-Medium, Roboto;
          font-weight: 500;
          color: #ffffff;
          line-height: 15px;
          border: none;
          animation: download 900ms ease-out infinite alternate;
        }

        @keyframes download {
          from {
            -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
            box-shadow: 0 0 5px rgba(0, 255, 0, 0.1),
              inset 0 0 5px rgba(0, 255, 0, 0.1), 0 0px 0 #393;
          }

          50% {
            -webkit-transform: scale3d(1.06, 1.06, 1.06);
            transform: scale3d(1.06, 1.06, 1.06);
            box-shadow: 0 0 10px rgba(0, 255, 0, 0.3),
              inset 0 0 8px rgba(0, 255, 0, 0.2), 0 0px 0 #393;
          }

          to {
            -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
            box-shadow: 0 0 20px rgba(0, 255, 0, 0.5),
              inset 0 0 10px rgba(0, 255, 0, 0.3), 0 0 0 #393;
          }
        }
      }
    }
  }
}

.main {
  margin: 44px 0;
  padding: 0 22px;
  .banners {
    width: 100%;
    .banner-item {
      img {
        width: 100%;
        height: 130px;
        object-fit: cover;
      }
    }
  }

  .section {
    margin-top: 30px;
    .section-title {
      font-size: 20px;
      font-family: Roboto-Bold, Roboto;
      font-weight: bold;
      color: #0a0a0a;
      line-height: 24px;
    }
    .section-slogn {
      margin-top: 8px;
      font-size: 12px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: #000000;
      line-height: 13px;
    }
    .section-content {
      margin-top: 20px;
    }
  }
}
.footer {
  background-image: url(https://file2.snkrsgram.com/images/2022/05/22/56063bd6a8e90ba0979d05ed12bfe52f.png);
  background-size: cover;
  position: relative;

  .footer-info {
    position: absolute;
    bottom: 0px;
    width: 100%;
    background: rgba(10, 10, 10, 0.6);

    .infos {
      padding: 22px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      text-align: left;
      color: #fff;
      a {
        color: #fff;
        text-decoration: none;
      }
      .info {
        .title1 {
          margin-bottom: 10px;
          font-size: 13px;
          font-family: Roboto-Medium, Roboto;
          font-weight: 500;
          color: #ffffff;
          line-height: 15px;
          letter-spacing: 1px;
        }
        .title2 {
          font-size: 11px;
          font-family: Roboto-Regular, Roboto;
          font-weight: 400;
          color: #ffffff;
          line-height: 14px;
        }
      }
    }
  }
}
</style>
