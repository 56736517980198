<template>
  <div class="hottest">
    <div class="hottest-title">
      <div class="title1">News & Hot Posts</div>
      <div class="title2">The Most Comprehensive Sneakers News</div>
    </div>
    <div class="topics">
      <div v-for="(item, index) in topics" :key="index" class="w-lc">
        <div
          class="lc-image"
          :style="{ backgroundImage: 'url(' + item.imageUrl + ')' }"
        ></div>
        <div class="lc-info">
          <div class="lc-title">{{ item.title }}</div>
          <div class="lc-meta">
            <div>#{{ item.tag }}</div>
            <div class="lc-user">
              <span>{{ item.nickname }}</span>
              <img :src="item.avatar" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      topics: [
        {
          imageUrl:
            "https://file2.snkrsgram.com/images/2021/11/02/c56bf9abd72bdc5698fa561dc05e72f0.jfif!preview",
          tag: "AJ Group",
          title:
            "Tough collection! Would you wish your room was this clean too? 🔥🔥",
          nickname: "Julio Covarrubias",
          avatar:
            "https://file2.snkrsgram.com/images/2021/09/25/6a2e76886b3f5474d5dd61f67b7a7a2b.jfif!avatar",
        },
        {
          imageUrl:
            "https://file2.snkrsgram.com/images/2021/11/01/8d6d19af9e68f031d642995fe5511fb6.jfif!preview",
          tag: "Kicks Kingdom",
          title: "Friends goals",
          nickname: "Rea",
          avatar:
            "https://file2.snkrsgram.com/images/2021/09/26/caf098ab187da847f163a4fe15b22c9f.jfif!avatar",
        },
        {
          imageUrl:
            "https://file2.snkrsgram.com/images/2021/10/29/3f52232ccf2b3d2789a01a7adbc05e8c.jfif!preview",
          tag: "Kicks Kingdom",
          title: "Cop or drop?",
          nickname: "Pat",
          avatar: "https://file2.snkrsgram.com/images/avatars/25.jpg!avatar",
        },
        // {
        //   imageUrl:
        //     "https://file2.snkrsgram.com/images/2021/11/03/58f49fc48b68b8fcdbf177afb02caf1e.jfif!preview",
        //   tag: "Kicks Kingdom",
        //   title: "Cop Or Drop?",
        //   nickname: "Emart",
        //   avatar: "https://file2.snkrsgram.com/images/avatars/18.jpg!avatar",
        // },
        // {
        //   imageUrl:
        //     "https://file2.snkrsgram.com/images/2021/11/03/e1e943cd88ddfbe961d5e7ded21f1518.jfif!preview",
        //   tag: "Kicks Kingdom",
        //   title: "Drawing muna",
        //   nickname: "TAMMY",
        //   avatar:
        //     "https://file2.snkrsgram.com/images/2021/10/15/fd25b12c809a4d10f1bcdb8d09fc643a.jfif!avatar",
        // },
        // {
        //   imageUrl:
        //     "https://file2.snkrsgram.com/images/2021/11/03/7fa9829c5057a1187554edd4ec314ae6.jfif!preview",
        //   tag: "Coins Event",
        //   title: "Share images",
        //   nickname: "Gian",
        //   avatar:
        //     "https://file2.snkrsgram.com/images/2021/10/19/a7f78e88220d60b96c1ad6d3cbd1f74e.jfif!avatar",
        // },
      ],
    };
  },
  mounted() {
    // this.load();
  },
  methods: {
    // async load() {
    //   const ret = await this.axios.get(
    //     "https://www.snkrsgram.com/api/topic/topics",
    //     {
    //       params: {
    //         appraiseOnly: true,
    //       },
    //     }
    //   );
    //   console.log(ret);
    // },
  },
};
</script>

<style lang="scss" scoped>
.hottest {
  margin: 0 176px;

  .hottest-title {
    padding-top: 235px;
    .title1 {
      height: 24px;
      font-size: 20px;
      font-family: Roboto-Bold, Roboto;
      font-weight: bold;
      color: #ffffff;
      line-height: 24px;
    }
    .title2 {
      margin-top: 8px;
      height: 13px;
      font-size: 12px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: #ffffff;
      line-height: 13px;
    }
  }
  .topics {
    margin-top: 51px;
    display: flex;
    justify-content: space-between;
    .w-lc {
      width: 297px;
      background-color: #fff;
      border-radius: 2px;
      position: relative;
      .yes-image {
        height: 40px;
        position: absolute;
        left: 0;
        top: 0;
      }
      .lc-image {
        width: 100%;
        height: 297px;
        background-size: cover;
        border-radius: 2px 2px 0px 0px;
      }
      .lc-info {
        background: #ffffff;
        border-radius: 2px;
        padding: 17px;

        .lc-title {
          font-size: 13px;
          font-family: Roboto-Bold, Roboto;
          font-weight: bold;
          color: #0a0a0a;
          line-height: 16px;
          height: 32px;
        }

        .lc-meta {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 9px;
          font-size: 12px;
          font-family: Roboto-Medium, Roboto;
          font-weight: 500;
          color: #363a3e;
          line-height: 13px;

          .lc-user {
            height: 20px;
            background: #f3f6fa;
            border-radius: 17px;
            display: flex;
            align-items: center;
            padding: 2px 8px;

            span {
              font-size: 10px;
              font-family: Roboto-Medium, Roboto;
              font-weight: 500;
              color: #363a3e;
              line-height: 12px;
            }
            img {
              margin-left: 4px;
              width: 20px;
              height: 20px;
              object-fit: cover;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
}
</style>
