<template>
  <div class="items">
    <div v-for="(item, index) in items" :key="index" class="item">
      <img :src="image(item.images)" class="product-img" />
      <div class="product-info">
        <div class="product-name">{{ item.name }}</div>
        <div class="product-fields">
          <div>
            <div class="product-field-name">Lowest quotation</div>
            <div class="product-field-value">₱ {{ item.minPrice }}</div>
          </div>
          <div>
            <div class="product-field-name">Grand total</div>
            <div class="product-field-value">₱ {{ item.soldCount }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 2,
        slidesPerGroup: 1,
        spaceBetween: 30,
        // 设置分页器
        pagination: {
          el: ".swiper-pagination",
          // 设置点击可切换
          clickable: true,
        },
        // 设置自动轮播
        autoplay: {
          delay: 5000,
        },
        // 设置轮播可循环
        loop: true,
      },
      mallData: null,
    };
  },
  methods: {
    image(images) {
      return images.split(",")[0];
    },
  },
};
</script>
<style lang="scss" scoped>
.items {
  display: flex;
  height: 276px;
  padding: 10px;
  margin: 0 -10px;

  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
  .item {
    margin-right: 20px;
    width: 180px;
    height: 256px;
    background: #ffffff;
    box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.1);
    border-radius: 1px;
    .product-img {
      width: 180px;
      height: 180px;
      border-radius: 2px 2px 0px 0px;
    }

    .product-info {
      padding: 8px;
      .product-name {
        font-size: 13px;
        font-family: Roboto-Medium, Roboto;
        font-weight: 500;
        color: #0a0a0a;
        line-height: 15px;
        text-shadow: 0px 4px 13px rgba(0, 0, 0, 0.1);

        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        text-align: justify;
        word-break: break-all;
        text-overflow: ellipsis;
      }

      .product-fields {
        display: flex;
        justify-content: space-between;
        .product-field-name {
          margin-top: 6px;
          font-size: 9px;
          font-family: Roboto-Regular, Roboto;
          font-weight: 400;
          color: #8f8f96;
          line-height: 10px;
          text-shadow: 0px 4px 13px rgba(0, 0, 0, 0.1);
        }
        .product-field-value {
          margin-top: 6px;
          font-size: 11px;
          font-family: LucidaGrande-Bold, LucidaGrande;
          font-weight: bold;
          color: #0a0a0a;
          line-height: 12px;
          text-shadow: 0px 4px 13px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}
</style>
