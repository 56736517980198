<template>
  <div class="items">
    <swiper :options="swiperOption" style="padding: 10px">
      <swiper-slide v-for="(item, index) in items" :key="index">
        <div class="item">
          <img :src="image(item.images)" class="product-img" />
          <div class="product-info">
            <div class="product-name">{{ item.name }}</div>
            <div class="product-fields">
              <div>
                <div class="product-field-name">Lowest quotation</div>
                <div class="product-field-value">₱ {{ item.minPrice }}</div>
              </div>
              <div>
                <div class="product-field-name">Grand total</div>
                <div class="product-field-value">₱ {{ item.soldCount }}</div>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
      <!-- <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div> -->
    </swiper>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 4,
        slidesPerGroup: 4,
        spaceBetween: 50,
        // 设置分页器
        pagination: {
          el: ".swiper-pagination",
          // 设置点击可切换
          clickable: true,
        },
        // navigation: {
        //   nextEl: ".swiper-button-next",
        //   prevEl: ".swiper-button-prev",
        // },
        // 设置自动轮播
        autoplay: {
          delay: 5000,
        },
        // 设置轮播可循环
        loop: true,
      },
      mallData: null,
    };
  },
  methods: {
    image(images) {
      return images.split(",")[0];
    },
  },
};
</script>
<style lang="scss" scoped>
.items {
  //   display: flex;
  .item {
    width: 210px;
    height: 325px;
    background: #ffffff;
    box-shadow: 0px 5px 17px 0px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    .product-img {
      width: 210px;
      height: 210px;
      border-radius: 2px 2px 0px 0px;
      //   box-shadow: 0px 5px 17px 0px rgba(0, 0, 0, 0.1);
    }

    .product-info {
      padding: 10px;
      position: relative;
      .product-name {
        font-size: 13px;
        font-family: Roboto-Medium, Roboto;
        font-weight: 500;
        color: #0a0a0a;
        line-height: 16px;
        height: 32px;
        text-shadow: 0px 5px 17px rgba(0, 0, 0, 0.1);
      }

      .product-fields {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        .product-field-name {
          height: 12px;
          font-size: 10px;
          font-family: Roboto-Regular, Roboto;
          font-weight: 400;
          color: #8f8f96;
          line-height: 12px;
          text-shadow: 0px 5px 17px rgba(0, 0, 0, 0.1);
        }
        .product-field-value {
          margin-top: 8px;
          height: 16px;
          font-size: 13px;
          font-family: Roboto-Bold, Roboto;
          font-weight: bold;
          color: #0a0a0a;
          line-height: 16px;
          text-shadow: 0px 5px 17px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}
</style>
