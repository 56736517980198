<template>
  <div class="popular-brands">
    <div class="brand-item" v-for="(item, index) in items" :key="index">
      <img :src="item.image" />
      <div class="brand-name">{{ item.catName }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.popular-brands {
  display: flex;
  padding: 10px;
  margin: 0 -10px;

  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
  .brand-item {
    margin-right: 20px;
    background: #ffffff;
    box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.1);
    border-radius: 1px;
    position: relative;

    img {
      width: 180px;
      height: 180px;
      object-fit: cover;
    }

    .brand-name {
      position: absolute;
      bottom: 5px;
      right: 5px;

      font-size: 18px;
      // font-family: Roboto-BoldItalic, Roboto;
      font-style: italic;
      font-weight: bold;
      color: #f3babe;
      text-shadow: 0px 4px 13px rgba(0, 0, 0, 0.2);
      // background-color: rgba(0, 0, 0, 0.1);
    }
  }
}
</style>
